(function($) {
    'use strict';
    var cookieName = 'analyticsOptOut',
        optOutStatus = false,
        cookieExpiresDays = 365 * 3,
        btnClass = 'js-tracking-opt-out',
        activeOptOutClass = 'is-active';

    function setOptOutCookie() {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + (cookieExpiresDays * 24 * 60 * 60 * 1000);
        now.setTime(expireTime);
        document.cookie = cookieName+'=1;expires='+now.toGMTString()+';path=/';
    }

    function removeOptOutCookie() {
        document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }

    function updateButtons(status) {
        if( status ) {
            $('.'+btnClass).addClass(activeOptOutClass);
        } else {
            $('.'+btnClass).removeClass(activeOptOutClass);
        }
    }



    if(document.cookie.indexOf(cookieName) === -1){
        $(document).on('click', '.'+btnClass, function(e){
            e.preventDefault();
            setOptOutCookie();
            updateButtons(true);
        });
    } else {
        optOutStatus = true;

        $(document).on('click', '.'+btnClass, function(e){
            e.preventDefault();
            removeOptOutCookie();
            updateButtons(false);
        });
    }

    updateButtons(optOutStatus);
})(jQuery);