(function($, window, undefined) {

        var $window = $(window),
            $html = $('html'),
            $body = $('body');

        $body.append('<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"><div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"><div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"><div class="pswp__top-bar"><div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button><button class="pswp__button pswp__button--share" title="Share"></button><button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button><button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button><div class="pswp__preloader"><div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"><div class="pswp__share-tooltip"></div></div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button><button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button><div class="pswp__caption"><div class="pswp__caption__center"></div></div></div></div></div>');
        var pswpElement = document.querySelectorAll('.pswp')[0];

        $window.on('initLightbox', function(){
            var items = [],
                options = {
                    index: 0
                },
                $lightboxButtons = $("a[rel='lightbox']");

            // add photos
            var key = 0;
            $lightboxButtons.each(function(){
                $a = $(this);

                items.push({
                    src: $a.attr('href'),
                    w: $a.data('width'),
                    h: $a.data('height'),
                    key: key
                });
                $a.data('key', key);
                key++;
            });


            $lightboxButtons.on('click', function(e){
                e.preventDefault();

                if( items.length ) {

                    var elemIndex = 0,
                        elemKey = $(this).data('key');

                    for(var i=0; i < items.length; i++) {
                        if( items[i].key == elemKey ) {
                            elemIndex = i;
                            break;
                        }
                    }

                    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, {index: elemIndex});
                    gallery.listen('close', function() {
                        $html.removeClass('lightbox-open');
                    });

                    gallery.init();
                    $html.addClass('lightbox-open');
                }

                return false;
            });
        });

        $window.trigger('initLightbox');
})(jQuery, window);