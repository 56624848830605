(function( $ ) {
    $.fn.hookScroll = function(options) {
        var settings = $.extend({
            callback: function(){},
            debounceValue: 30
        }, options );

        var debounce = function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        var $window = $(window);
        var lastScrollPos = 0,
            //callback = settings.callback(),
            debounceValue = debounceValue||30,
            cb = function() {
                scrollTop = $window.scrollTop()||0;
                lastScroll = lastScrollPos;
                diff = scrollTop - lastScroll;
                direction = 0;
                if( diff > 0 ) { direction = 1; }
                else if( diff < 0 ) { direction = -1; }

                settings.callback({
                    scrollTop: scrollTop,
                    previousScrollTop: lastScrollPos,
                    direction: direction
                });

                lastScrollPos = scrollTop;
            };

        cb();

        // check https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
        var supportsPassive = false;
        try {
            var opts = Object.defineProperty({}, 'passive', {
                get: function() {
                    supportsPassive = true;
                }
            });
            window.addEventListener("test", null, opts);
        } catch (e) {}

        window.addEventListener('load', function() {
            cb();
        }, supportsPassive ? { passive: true } : false);

        if( supportsPassive ) {
            window.addEventListener('scroll', function() {
                cb();
            }, supportsPassive ? { passive: true } : false);
        } else {
            if( settings.debounceValue > 0 ) {
                window.addEventListener('scroll', debounce(function(){
                    cb();
                }, settings.debounceValue));
            } else {
                window.addEventListener('scroll', function(){
                    cb();
                });
            }
        }

        return this;
    };

}( jQuery ));