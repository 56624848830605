(function($) {
    'use strict';

    var $html = $('html'),
        $mainNav = $('.main-nav'),
        navOpenClasses = {
            subnav: 'focus', //'open-subnav',
            megamenu: 'focus' //'open-megamenu'
        };


    var freezeVp = function(e) {
        e.preventDefault();
    };

    var stopBodyScrolling = function(bool) {
        if (bool === true) {
            document.body.addEventListener("touchmove", freezeVp, false);
        } else {
            document.body.removeEventListener("touchmove", freezeVp, false);
        }
    };

    if( $mainNav.length ) {
        var $mainNavList = $mainNav.find('.main-nav--list'),
            $firstLevelLiWithSubnav = $mainNavList.find('> li.has-subnav'),
            $firstLevelLiWithMegamenu = $mainNavList.find('> li.has-megamenu'),
            $metaNav = $('.main-nav--metanav'),
            $langNav = $metaNav.find('.languages'),
            $mainNavItems = $mainNavList.find('li');


        $('.js-toggle-mobile-nav').click(function(e){
            e.preventDefault();

            if($html.hasClass('main-nav--mobile--open')) {
                stopBodyScrolling(true);
            } else {
                stopBodyScrolling(false);
            }

            $html.toggleClass('main-nav--mobile--open');
            $(this).toggleClass('is-active');
        });


        // double tap for touch
        var isTouchDevice = ("ontouchstart" in document.documentElement);
        if(isTouchDevice) {
            $mainNavItems.each(function(){
                var $currItem = $(this),
                    $subItems = $currItem.find('li > a'),
                    $button = $currItem.find('> a');

                if( $subItems.length && $button.length ) {
                    $button.on('click', function(e){
                        if( !$currItem.hasClass('js-tapped') ) {
                            e.preventDefault();

                            $mainNavItems.removeClass('js-tapped');
                            $currItem.addClass('js-tapped');
                            $(this).trigger('focus');
                        }
                    }).on('blur', function(){
                        $currItem.removeClass('js-tapped');
                    });
                }
            });
        }
    }
})(jQuery);

