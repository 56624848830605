(function($) {
    'use strict';
    var $elements = $('.js-slider-simple');

    if( $elements.length ) {
        // data-slick konfiguration bei einzelnen elementen verwenden
        // https://github.com/kenwheeler/slick/

        // defaults setzen
        $elements.on('init', function(event, slick){
            var hasContent = $(this).find('.content-element--media--slider--content').length;
            if( hasContent > 0 ) {
                $(this).addClass('content-element--media--slider-hasContent');
                var imgHeight = $(this).find('img').first().height();
                $(this).find('.slick-arrow').css('top', (imgHeight/2)+'px');
                console.log('hasContent', hasContent, imgHeight);
            }
        });
        $elements.slick({
            easing: 'ease-in-out',
            nextArrow: '<button type="button" class="slider-next">Next</button>',
            prevArrow: '<button type="button" class="slider-prev">Previous</button>'
        });
    }
})(jQuery);