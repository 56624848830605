// social stream
(function($) {
    'use strict';
    var $element = $('#cnss-widget');
    if($element.length) {

        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://social-stream.conecto.at/embed.js';
        document.getElementsByTagName('head')[0].appendChild(script);

    }
})(jQuery);