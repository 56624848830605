
// svg animate
(function($) {
    'use strict';
    var $element = $('.svg-animate'),
        animateCurrentClassName = 'svg-animate--layer-current',
        intervalLength = 200;
    if($element.length) {

        $element.each(function() {
            var $svg = $(this),
                $layers = $svg.find('.svg-animate--layer'),
                numLayers = $layers.length;

            var currLayerIndex = 0;
            setInterval(function(){
                $layers.removeClass(animateCurrentClassName);
                $($layers[currLayerIndex]).addClass(animateCurrentClassName);
                //console.log('svg anim', currLayerIndex, numLayers);

                currLayerIndex++;

                if(currLayerIndex >= numLayers) {
                    currLayerIndex = 0;
                }
            }, intervalLength);

        });

    }
})(jQuery);