
// scrolling
(function($, cn_scrollDirection_scrolledState, undefined) {
    'use strict';


    var $window = $(window),
        viewportHeight = $window.height(),
        $html = $('html'),
        $scrolledStateOffsetElement = $('.main-nav').first(),
        hasHeaderHero = $('body').hasClass('frontpage');

    var config = {
        offset: {
            scrolledState: 0,
            //scrolledStateAdvanced: $('.header-slider').height()
            scrolledStateAdvanced: hasHeaderHero ? (viewportHeight*0.25) : (viewportHeight*0.25)
        },
        classes: {
            SCROLLED: 'scrolled',
            SCROLLEDADVANCED: 'scrolled-advanced',
            SCROLLUP:'scrolling-up',
            SCROLLDOWN: 'scrolling-down'
        }
    };

    var setScrolledStateOffset = function() {
        var elemHeight = $scrolledStateOffsetElement.outerHeight(),
            elemOffsetTop = $scrolledStateOffsetElement.offset().top;

        //console.log(scrollState, elemHeight, elemOffsetTop);
        var scrollState = elemOffsetTop; //elemOffsetTop + elemHeight;
        config.offset.scrolledState = scrollState;
    };

    //setScrolledStateOffset();

    if ( $window.scrollTop() > viewportHeight ) {
        $html.addClass( config.classes.SCROLLED ).addClass( config.classes.SCROLLEDADVANCED );
    }

    $window.hookScroll({
        callback: function(data) {
            if( data !== undefined ) {
                var scroll = data.scrollTop;
                if ( scroll > config.offset.scrolledState ) {
                    $html.addClass( config.classes.SCROLLED ).removeClass( config.classes.SCROLLEDADVANCED );
                    if ( scroll > config.offset.scrolledStateAdvanced ) {
                        $html.addClass( config.classes.SCROLLEDADVANCED );
                    }
                } else {
                    $html.removeClass( config.classes.SCROLLED );
                }

                if( data.direction > 0 ) {
                    $html.addClass( config.classes.SCROLLDOWN )
                        .removeClass( config.classes.SCROLLUP );
                } else {
                    $html.addClass( config.classes.SCROLLUP )
                        .removeClass( config.classes.SCROLLDOWN );
                }

                if( scroll === 0 ) {
                    $html
                        .removeClass( config.classes.SCROLLED )
                        .removeClass( config.classes.SCROLLEDADVANCED )
                        .removeClass( config.classes.SCROLLUP )
                        .removeClass( config.classes.SCROLLDOWN )
                    ;
                }
            }
        }
    });
})(jQuery);